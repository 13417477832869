// CSS styles for the banner modal
.banner-dialog {
  z-index: 10002;
}

.banner-dialog-content {
  background-color: #24263c;
  color: white;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
}

// CSS styles for the banner modal title
.banner-dialog-title {
  text-align: center;
  background-color: #393c56;
  color: white;
}

// CSS styles for the banner modal content text
.banner-dialog-content-text {
  color: white;
  font-family: "Inter";
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

// CSS styles for the dialog actions
.dialog-actions {
  background-color: #393c56;
  color: white;
  display: flex !important;
  justify-content: space-between !important;
  // gap: 1;
  min-height: 5em;
  padding: 1em;
}

// CSS styles for the disagree button
.disagree-button {
  text-transform: none !important;
  background-color: #393c56 !important;
  color: white !important;

  &:hover {
    background-color: white !important;
    color: black !important;
  }
}

// CSS styles for the agree button
.agree-button {
  text-transform: none !important;
  border: 1px solid #ffffff !important;
  background-color: #393c56 !important;
  color: white !important;

  &:hover {
    background-color: white !important;
    color: black !important;
  }
}

.banners-container {
  display: block;
  padding-top: 1em;
}

.box-icon {
  height: 0.5em;
  width: 0.5em;
  background-color: #ffffff;
  margin: auto 0.2em;
}

.banner-point {
  color: #ffffff;
  padding-left: 0.2em;
}
