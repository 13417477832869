.customInput {
  border: 1px solid #ffffff40;
  background: #00000080;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #fff;
  width: 100%;
  outline: none;
  & ::-webkit-inner-spin-button,
  & ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #00000080;
    caret-color: #fff;
  }
}

.passwordInput {
  position: relative;
  input {
    padding-right: 50px;
  }
  .eyeIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    svg {
      color: white;
      transform: translateY(2px);
    }
  }
}
