.icon {
  font-size: 20px;
  fill: #ffffff;
  cursor: pointer;
}
.menuWrap {
  background-color: #1b1d2d !important;
  .menuItem {
    color: #fff;
    background-color: #1b1d2d;
    .logoutButtonWrap {
      cursor: pointer;
    }
    &:hover {
      background-color: #393c56;
    }
  }
}
