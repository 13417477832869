.shipFiltersDetail {
  .shipFiltersLabel {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #ffffff;
  }
  .shipFiltersPlaceholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #ffffff40;
  }
  .shipFilterSelect {
    border: 1px solid #ffffff40;
    background: #00000080;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #ffffff;
    padding: 8px 12px;
    border-radius: 4px;
    .inputSelect {
      padding: 0;
    }
    .inputIcon {
      color: #fff;
    }
  }
}
.shipFilterUnavailable {
  color: #fff;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.shipFilterOptions {
  padding: 6px 12px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  gap: 6px;
  &.shipFilterOptionsSelected {
    background-color: #00a3e34d !important;
  }
  .shipFilterOptionsText {
    color: #fff;
  }
  .checkboxMain {
    color: #fff;
    padding: 0;
    &.checkboxChecked {
      color: #0094ff;
    }
  }
}
