.Checkbox {
  display: flex;
  align-items: center;
  gap: 7px;
  margin: 0;
  .Check {
    width: 20px;
    height: 20px;
    padding: 0;
    color: #0094ff !important;
    position: relative;
    z-index: 5;
    &.CheckTick {
      &::after {
        content: "";
        position: absolute;
        background-color: #fff;
        border-radius: 0;
        width: calc(100% - 5px);
        height: calc(100% - 5px);
        z-index: -1;
        top: 3px;
        left: 3px;
      }
    }
  }
  .CheckboxLabel {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    user-select: none;
  }
}
