.homeMain {
  padding: 0px 50px 40px;
  overflow: auto;
  height: calc(100vh - 53px);
  .homeWrap {
    padding: 40px 0 20px;
    background: #24263c;
    .recentHead {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
    }
    .viewedByWrap {
      .lastViewedWrap {
        .lastViewed {
          color: #fff;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .gridIcon,
      .listIcon {
        cursor: pointer;
      }
    }
    .CardAddText {
      color: #fff;
      font-size: 20px;
      font-weight: 400;
    }
  }
  .homeDashbordMain {
    width: 100%;
    .homeDashbord {
      .homeDashbordCard {
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;
        width: 100%;
        .dashbordCardAdd {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 8px;
          border-radius: 4px;
          border: 1px solid #fff;
          height: 280px;
          .CardAddIcon {
            font-size: 60px;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}
