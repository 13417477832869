.polygonShipSlider {
  border-top: 1px solid #393c56;
  padding-top: 25px;
  margin-top: 25px;
  .polygonShipSliderText {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
  .polygonShipSliderIcon {
    cursor: pointer;
    padding: 0;
    min-width: unset;
    color: white !important;
  }
  .polygonShipSliderIconDisabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
  }
  .polygonShipSliderDate {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
  .polygonShipButton {
    width: auto;
    min-width: 165px;
  }
  .polygonShipSliderInput {
    color: #24a0ca;
    margin: 0 1rem 0;
  }
  .polygonShipSliderInputDisabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
}
