.sharedMain {
  padding: 0px 50px 40px;
  overflow: auto;
  height: calc(100vh - 53px);
  .sharedWrap {
    padding: 40px 0 20px;
    background: #24263c;
    .CardAddText {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
    }
  }
  .sharedWorkMain {
    width: 100%;
  }
}
