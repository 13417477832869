.futurePathPredictionSlider {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .futurePathPredictionSliderContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .futurePathPredictionSliderlabel {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }
  }
  .futurePathPredictionSliderOption {
    .futurePathPredictionSliderBox {
      width: 24px;
      height: 12px;
      border: 1px solid #d03527;
      &.mostlikely {
        background-color: #d03527bf;
      }
      &.likely {
        background-color: #d0352780;
      }
      &.possible {
        background-color: #d0352726;
      }
    }
    .futurePathPredictionSliderBoxLabel {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #eeeeee;
    }
  }
}
