.rightNavigationBar {
  position: absolute;
  top: 2%;
  right: 1%;
  height: calc(100vh - 230px);
  z-index: 5;
  .mapNavigationoptions {
    gap: 10px;
    .mapNavigationIcon {
      color: #fff;
      background-color: #24263c;
      border-radius: 5px;
      width: 40px;
      height: 40px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        background-color: #00a3e3;
      }
      &.activeIcon {
        background-color: #00a3e3;
      }
    }
    .mapNavigationIconWrap {
      background-color: #24263c;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
      .navigationDivider {
        border-color: #393c56;
      }
    }
    .shipSvgWrap {
      cursor: pointer;
    }
  }
}
