.oSIntelSidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 16px 16px 16px;

  .osIntelTitle {
    font-size: 14px;
    font-weight: 700;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
