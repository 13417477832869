.futurePathPredictionLoader {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .linearProgressBar {
    background-color: #393c56;
    .linearProgressBarFill {
      background-color: #ffffff;
    }
  }
  .loadingContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .cancelBtn {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      padding: 0;
      color: #ffffff;
      text-transform: capitalize;
    }
  }
}
