.cutomSelectWrap {
  gap: 6px;
  .shipToolLabel {
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: #ffffff80;
  }
  .selectFormControl {
    .customSelectMain {
      background-color: #393c56;
      color: #ffffff;
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      font-family: "Inter";
      line-height: 16px;
      overflow: hidden;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 40px;
        width: 1px;
        height: 100%;
        background-color: #ffffff40;
      }
      .customSelect {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 12px 16px;
        max-height: 40px;
        min-height: unset;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      &:global {
        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border: 0;
          }
        }
      }
    }
  }
}

.selectRoot {
  .selectMenu {
    background-color: #393c56;
    margin-top: 3px;
    .selectList {
      padding: 0;
      .searchMenuItem {
        padding: 12px 16px;
        span {
          font-size: 10px;
          color: #fff;
          font-weight: 400;
          line-height: 12px;
        }
      }
      .selectMenuItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        font-family: "Inter";
        color: #fff;
        padding: 12px 16px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        background-color: #393c56;
        &:hover {
          background-color: #0094ff;
        }
        &.menuItemDisabled {
          pointer-events: all !important;
          cursor: not-allowed !important;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
