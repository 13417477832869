.polygonShipMinimize {
  position: absolute;
  top: 77px;
  right: 65px;
  background: #24263c;
  box-shadow: 0px 4px 4px 0px #00000040;
  z-index: 999;
  padding: 15px 25px;
  border-radius: 8px;
  .polygonShipMinimizeIcon {
    cursor: pointer;
  }
}
