.customDatePicker {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .icon {
    color: #fff;
  }
  .datePickerMain {
    min-width: 140px;
    :global .MuiInputBase-root {
      padding-right: 12px;
      input.MuiInputBase-input {
        padding: 6px 0px 6px 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #fff;
      }
      .MuiInputAdornment-root {
        height: 100%;
        width: 24px;
        margin-left: 0;
        .MuiButtonBase-root {
          padding: 0;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #ffffff40;
      }
    }
  }
    .largeDatePicker {
    :global .MuiInputBase-root {
      input.MuiInputBase-input {
        padding: 12px 0px 12px 12px;
      }
    }
  }
}
