.customSliderMain {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .customSliderHead {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
  .customSliderWrapper {
    .customSliderDetail {
      .editMainSelect {
        color: #fff;
        border-radius: 4px 0 0 4px;
      }
      .playPauseButton {
        background: #00a3e3;
        border-radius: 0 4px 4px 0;
        padding: 5px 10px;
        color: #fff;
        transition: all 0.5s ease-in-out;
        width: 32px;
        cursor: pointer;
      }
    }
  }

  .sliderMain {
    .sliderRail {
      color: #00a3e3;
    }
    .sliderTrack {
      color: #00a3e3;
    }
    .sliderThumb {
      color: #00a3e3;
      border: 2px solid #ffffff;
    }
  }
}
