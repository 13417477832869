.markerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  z-index: 9;
}
.markerMain {
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  gap: 0px;
  min-width: 100px;
  max-width: 200px;
  position: relative;
  bottom: 12px;
  display: flex;
  gap: 0px;
  flex-direction: column;

  .markerIcon {
    padding: 15px;
    background-color: #24263c;
    .markerClose {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
  }
  .markerBody {
    padding: 15px;
    padding-top: 0px;
    background-color: #111326;
    .markerTetx {
      display: flex;
      align-items: center;
      gap: 5px;
      border-bottom: 1px solid #393c56;
      padding: 5px;
      &:last-child {
        border-bottom: 0;
      }
      &:first-child {
        margin-top: 3px;
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #24263c;
  }
}
