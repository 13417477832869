.shipToolsModal {
  background: #111326;
  width: 530px;
  border-radius: 8px;
  overflow: hidden;
  .shipToolsBody {
    padding: 24px;
    border: 1px solid #393c56;
  }
}
.minimizeModal {
  display: none;
}
