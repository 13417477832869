.nameButton {
  padding: 0 !important;
  text-align: start !important;

  .tableData {
    font-size: 12px;
    color: #ffffff;
    text-transform: capitalize;
    display: block !important;
    overflow: hidden !important;
    width: 70px !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
}

.shipTabMain {
  width: 100%;

  .shipDetailsTab {
    display: flex;
    border-bottom: 1px solid #393c56;

    .shipTab {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #7d7d84;
      padding: 13px 16px;
      text-transform: capitalize;
      flex: 1;
      text-align: center;

      &.shipTabSelected {
        color: #fff;
      }
    }

    .shipTabIndicator {
      background-color: #fff;
    }
  }
}
