.modelHeadWrap {
  padding: 16px 24px;
  background-color: #24263c;

  .modelHead {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
  .headerIcon {
    cursor: pointer;
  }

  .headerIconDisabled {
    cursor: not-allowed;
    color: grey;
  }
}
