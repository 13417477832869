.collapsedDetail {
  position: fixed;
  height: calc(100vh - 121px);
  top: 121px;
  z-index: 99;
  background-color: #111326;

  .collapseIconWrap {
    position: relative;
    height: 100%;

    .collapseIcon {
      position: absolute;
      top: 11px;
      right: -14px;
      min-width: 24px;
      height: 24px;
      color: white;
      padding: 0;
      background-color: #393c56;
      border-radius: 4px;
    }
  }
}
