.sidebar {
  position: fixed;
  left: 0;
  width: 50px;
  height: calc(100vh - 121px);
  top: 121px;
  z-index: 100;
  background-color: #393c56;
  display: flex;
  justify-content: center;

  .sidebarIconsButtonWrap {
    // padding: 5px 0;
    display: flex;
    flex-direction: column;

    .sidebarIconsButton {
      display: flex;
      justify-content: center;
      width: 50px;
      height: 50px;
    }
  }

  .sidebarButton {
    color: white;
    text-align: center;
  }
}
