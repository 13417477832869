.customSelect {
  color: #fff !important;
  font-size: 12px !important;
  fieldset {
    border: none;
  }
  svg {
    width: 22px;
    margin-left: -25px;
  }
}
.selectRoot {
  .selectMenu {
    background-color: #393c56;
    margin-top: 3px;
    .selectList {
      padding: 0;
      .selectMenuItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        font-family: "Inter";
        color: #fff;
        padding: 12px 16px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        background-color: #393c56;
        &:hover {
          background-color: #0094ff;
        }
      }
    }
  }
}
.eventMain {
  .eventWrap {
    .eventCard {
      position: relative;
      padding-left: 20px;
      padding-bottom: 20px;
      &:last-child {
        padding-bottom: 0;
      }
      &::before {
        content: "";
        position: absolute;
        top: 3px;
        left: 0;
        height: 100%;
        border-left: 1px dashed #fff;
      }
      &::after {
        content: "";
        position: absolute;
        top: 3px;
        left: -3px;
        width: 8px;
        height: 8px;
        border-radius: 2px;
        background-color: #fff;
      }
      .eventCardHead {
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        color: #fff;
      }
      .eventDetailWrap {
        gap: 14px;
        .detailWrap {
          .detailHead {
            font-family: "Inter";
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            color: #7d7d84;
          }
          .detailValue {
            font-family: "Inter";
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            color: #fff;
            &.light {
              color: #0094ff;
            }
            &.spoofing {
              color: #fc85a9;
            }
            &.bunkering {
              color: #55efe6;
            }
            &.portOfCalls {
              color: #7be27b;
            }
            &.dark {
              color: #ffd966;
            }
          }
        }
      }
      .eventCardButton {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        border-color: #fff;
        color: #fff;
        padding: 8px 30px;
        margin-bottom: 16px;
        text-transform: capitalize;
        width: 225px;
      }
    }
  }
}
