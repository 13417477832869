.recentMain {
  padding: 0px 50px 40px;
  overflow: auto;
  height: calc(100vh - 53px);
  .recentWrap {
    padding: 40px 0 20px;
    background: #24263c;
    .recentHead {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
    }
    .viewedByWrap {
      .lastViewedWrap {
        .lastViewed {
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          border-bottom: none;
          &::before {
            border-bottom: unset;
            content: none;
          }
          &::after {
            border-bottom: unset;
          }
          svg {
            fill: #fff;
          }
        }
      }
      .gridIcon,
      .listIcon {
        cursor: pointer;
      }
    }
  }
}
