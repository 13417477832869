.headerContainer {
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  margin-bottom: 15px;

  .subHeaderMain {
    display: flex;
    align-items: center;
    padding: 15px 24px 0px 24px;
    box-shadow: 0px 6px 8px 0px #00000033;
    position: sticky;
    z-index: 99;
    background-color: #24263c;

    .subHeaderText {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
    .subHeaderFilterText {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }
    .divider {
      border-color: #ffffff4d;
    }
    .stateButton {
      background: transparent;
      border-radius: 5px;
      display: flex;
      align-items: center;
      width: 170px;
      gap: 5px;
      color: #fff;
      border: 1px solid transparent;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      padding: 8px 15px;
      transition: all 0.5s;
      &.savedButton {
        width: 120px;
      }
      &.downloadButton {
        width: 140px;
      }
      &:hover {
        border: 1px solid #fff;
        background: transparent;
      }
      &.disabled {
        color: #9b9b9b;
        pointer-events: all;
        &:hover {
          border-color: #9b9b9b;
        }
      }
      .circularSpiner {
        color: #fff;
      }
    }
    .datePickerContainer {
      :global .MuiFormControl-root {
        .MuiFormLabel-root,
        .MuiInputBase-input {
          display: none;
        }
        .MuiButtonBase-root {
          margin: 0;
          color: #fff;
        }
        .MuiInputBase-root {
          padding: 0;
          background: transparent;
          &:hover {
            &::before {
              border-bottom: none;
            }
          }
          &::before {
            border: 0;
          }
          &::after {
            border: 0;
          }
          .MuiInputAdornment-root {
            height: unset;
            max-height: unset;
            margin: 0;
          }
          .MuiOutlinedInput-notchedOutline {
            border: 0;
            padding: 0;
          }
        }
      }
    }
  }
}

.mapFilterOptions {
  color: #ccc;
  background-color: #fff;
  z-index: 100;
  position: fixed;

  bottom: 5%;
  right: 5%;
}

.filterOptions {
  padding: 1rem;
}

.singleFilterbox {
  margin-top: 1rem;
}

.shipToolsModalMain {
  position: fixed;
  bottom: 2%;
  right: 5%;
  z-index: 99;
}
