.mapfilterbox {
  background-color: whitesmoke;
}
.filterOptions {
  padding: 1rem;
}

.singleFilterbox {
  margin-top: 1rem;
}

.singleFilterbox {
  margin-top: 1rem;
}

.filtersMain {
  .filterSelector {
    z-index: 9;
    position: fixed;
    bottom: 2%;
    right: 80px;
    box-sizing: border-box;
    width: 365px;
    border-radius: 4px;
    overflow: hidden;
    background: #393c56;

    .shipFiltersHead {
      background: #24263c;
      padding: 15px;
      color: #fff;
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      svg {
        cursor: pointer;
      }
    }
    .mapCheckbox {
      padding: 15px;
      border-bottom: 1px solid #24263c;
      .checkboxMain {
        color: #fff;
        padding: 0;
        &.checkboxChecked {
          color: #0094ff;
        }
      }
      .mapCheckboxLabel {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: left;
      }
      pointer-events: none;
      opacity: 0.5;
    }
    .radioMain {
      color: #fff;
      padding: 0;
      &.radioChecked {
        color: #0094ff;
      }
    }
    .accordionLabel {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #ffffff;
      text-transform: capitalize;
    }
    .accordionMain {
      background-color: transparent;
      margin: 0;
      box-shadow: none;
      &::before {
        background-color: transparent;
      }
      .accordionWrap {
        background-color: transparent;
        border: 0;
        padding: 15px 15px;
        .expandMoreIcon {
          color: #fff;
        }
        .accordionContent {
          margin: 0;
          align-items: center;
          gap: 8px;
        }
      }

      .accordionDetail {
        padding: 0;
      }
    }

    .layerSelector {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      padding-left: 15px;
      padding-bottom: 5px;
    }

    .mapLayersWrap {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid gray;
      padding: 20px 15px 20px 15px;
      justify-content: center;
      gap: 16px;
      cursor: pointer;

      .LayerContentWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        .mapLayer {
          border-radius: 4px;
          background: transparent;
          width: 100px;
          height: 100px;
          overflow: hidden;
        }
        .mapTitle {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
        }
        .LightImg {
          width: 100px;
          height: 100px;
          border-radius: 4px;
        }
      }
      .disabledWrap {
        cursor: not-allowed !important;
        opacity: 0.5;
      }
      .selectedBox {
        border: 2px solid #0094ff;
        border-radius: 4px;
      }
    }
  }
}
