button.customButtonComponent {
  padding: 8px 12px;
  background-color: #00a3e3;
  color: #fff;
  font-size: 13.5px;
  font-weight: 500;
  line-height: 17px;
  text-transform: capitalize;
  width: 106%;
  border: 1px solid transparent;
  &:hover {
    background-color: #00a3e3 !important;
    color: #fff !important;
  }
  &:disabled {
    background-color: #24263c !important;
    color: #000 !important;
    cursor: not-allowed !important;
  }
  &.buttonOutlined {
    border-color: #fff;
    background: transparent;
  }

  .circularSpiner {
    color: #fff;
  }
}
