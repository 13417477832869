.selectInputComponent {
  max-height: 34px;
  font-size: 14px;
  padding: 8px 12px;
  line-height: 17px;
  background-color: #00000080;
  border: 1px solid #ffffff40;
  &:global {
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }
  }
  .selectInnerWrap {
    &.selectOutlined {
      padding: 0;
      min-height: unset;
    }
  }
  .selectIcon {
    fill: #ffffff;
  }
}

.selectInputRoot {
  .selectInputMenu {
    background-color: #000000;
    margin-top: 3px;
    .selectInputList {
      padding: 0;
      .selectInputMenuItem {
        color: #fff;
        padding: 12px 16px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        &:hover {
          background-color: #0094ff;
        }
      }
    }
  }
}
