.dashboardWrapper {
  .homeDashbordCard {
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    width: 100%;
    .dashbordCardMedia {
      border-radius: 4px;
      border: 1px solid #fff;
      pointer-events: none;
    }
    .dashbordCardContent {
      display: flex;
      flex-direction: column;
      gap: 6px;
      background: transparent;
      padding: 0;
      margin-top: 12px;
      .CardContentHead {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
      }
      .CardContentTime {
        color: #fff;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}

.headerTabDeleteIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #393c56;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.headerTabEditIcon {
  position: absolute;
  top: 10px;
  right: 40px;
  background: #393c56;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.headerTabShareIcon {
  position: absolute;
  top: 10px;
  right: 72px;
  background: #393c56;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.tooltipButton {
  background-color: #1b1d2d !important;
  border-radius: 5px !important;
  padding: 8px 15px !important;
  font-size: 12px !important;
  margin-top: 10px !important;
  .tooltipArrowButton {
    &::before {
      background-color: #1b1d2d !important;
    }
  }
}
