.dropPinWrapper {
  width: 17%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #111326;
  border-radius: 8px;
  overflow: hidden;
  z-index: 9;

  .dropPinViewButton {
    text-transform: capitalize;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }

  .EditPathMain {
    padding: 24px;
    border: 1px solid #393c56;
    border-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .editMainWrapper {
      gap: 12px;
      .editMainLabel {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
      }
      .editMainSelect {
        color: #fff;
      }
      .editMainInput {
        border: 1px solid #ffffff40;
        background: #0a0b12;
        border-radius: 4px;
        [class*="MuiOutlinedInput-input"] {
          color: #fff;
          padding: 8px 12px;
          height: auto;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &[type="number"] {
          -moz-appearance: textfield;
        }
      }
      .editMainSelect {
        border: 1px solid #ffffff40;
        background: #0a0b12;
        border-radius: 4px;
        padding: 8px 12px;
        height: 39px;
      }
    }
    .editMainButton {
      text-transform: capitalize;
      padding: 8px 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: #ffffff;
      border-color: #ffffff;
      &:hover {
        border-color: #ffffff;
      }
    }
  }
  .dropPinButton {
    border-top: 1px solid #393c56;
    padding: 24px 0;
    margin: 0 24px;
  }
}
