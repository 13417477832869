.attrubutionModalWrap {
  .attrubutionModal {
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #393c564a;
    background-color: #0d0e16;
    .title {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      padding: 12px;
    }

    .btnWrapper {
      padding: 8px;
      .linkBtn {
        border: 1px solid#bbb;
        border-radius: 5px;
        color: #bbb;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
        padding: 8px 25px;
        &:hover {
          background: #fff;
          color: #24263c;
        }
      }

      .saveBtn {
        border: 1px solid transparent;
        border-radius: 5px;
        color: #24263c;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 600;
        padding: 8px 25px;
        background: #fff;
        &:hover {
          border: 1px solid#bbb;
          background: transparent;
          color: #bbb;
        }
      }
    }
  }
}
