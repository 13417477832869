.dialogMain {
  .dialogWrap {
    margin: 0;
    width: 65%;
    background: #0d0e16;
    border: 1px solid #393c564a;
    padding: 15px;
    .dialogHead {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
    }
    .dialogtext {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      text-transform: capitalize;
    }
    .modeWrap {
      padding: 16px 0 0;
      gap: 15px;
      .modesHead {
        color: #fff;
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
      }
      .radioGroup {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .radioButton {
          color: #fff;
        }
        .MuiSvgIcon-root {
          color: #fff;
        }
      }
    }
    .dialogTextFild {
      color: #fff;
      .dialogLabel {
        color: #fff;
        &.dialogLabelFocused {
          color: #bbb;
        }
      }

      .dialogInput {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        &::before {
          border-color: #fff;
        }
        &.dialogInputFocused {
          &::after {
            border-color: #bbb;
          }
        }
      }
    }
    .button {
      border: 1px solid#bbb;
      border-radius: 5px;
      color: #bbb;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 600;
      padding: 8px 25px;
      &:hover {
        background: #fff;
        color: #24263c;
      }
    }
    .buttonCreate {
      border: 1px solid transparent;
      border-radius: 5px;
      color: #24263c;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 600;
      padding: 8px 25px;
      background: #fff;
      margin-left: 20px;
      &:hover {
        border: 1px solid#bbb;
        background: transparent;
        color: #bbb;
      }
    }
  }
}
