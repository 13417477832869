.ownershipWrap {
  display: grid;
  gap: 10px;
  .ownerDetailWrap {
    gap: 2px;
    .ownerDetailHead {
      font-family: "Inter";
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      color: #7d7d84;
    }
    .ownerDetailValue {
      font-family: "Inter";
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #fff;
    }
  }
}
