.mapFilterSelector {
  color: #000;
  z-index: 5;
  position: absolute;
  bottom: 2%;
  right: 5%;
  box-sizing: border-box;
  width: 365px;
  border-radius: 4px;
  overflow: hidden;
}
.mapfilterbox {
  background-color: whitesmoke;
}

.filterOptions {
  padding: 15px;
  background: #24263c;
  border: 1px solid #393c56;
  color: #fff;
  .filterTypeWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .singleOption {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-transform: capitalize;
    }
    .checkboxMain {
      color: #fff;
      padding: 0;
      &.checkboxChecked {
        color: #0094ff;
      }
    }
    .tuneWrap {
      cursor: pointer;
    }
  }
}
