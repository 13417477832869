.futurePathBody {
  .futureBodyViewButton {
    text-transform: capitalize;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
  .futureBodyEditButton {
    text-transform: capitalize;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #ffffff;
    border-color: #ffffff;
    &:hover {
      border-color: #ffffff;
    }
  }
  .futureBodyText {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
  .EditPathMain {
    border-bottom: 1px solid #393c56;
    padding-bottom: 24px;
    column-gap: 24px;
    margin-bottom: 24px;
    row-gap: 16px;
    .editMainWrapper {
      gap: 12px;
      .editMainLabel {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
      }
      .editMainSelect {
        color: #fff;
      }
      .editMainInput {
        border: 1px solid #ffffff40;
        background: #0a0b12;
        border-radius: 4px;
        [class*="MuiOutlinedInput-input"] {
          color: #fff;
          padding: 8px 12px;
          height: auto;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &[type="number"] {
          -moz-appearance: textfield;
        }
      }
      .editMainSelect {
        border: 1px solid #ffffff40;
        background: #0a0b12;
        border-radius: 4px;
        padding: 8px 12px;
        height: 39px;
      }
    }
    .editMainButton {
      text-transform: capitalize;
      padding: 8px 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: #ffffff;
      border-color: #ffffff;
      &:hover {
        border-color: #ffffff;
      }
    }
  }
}
