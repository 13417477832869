

.container {
width: 100%;
padding-top : 1em;
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to the next row */
  justify-content: space-between; /* Adds space between the two columns */
  margin: 0 auto; /* Centers the container horizontally */
}

.itemtitle{
  font-size: 12px !important;
  font-family: Inter;
  font-weight: 400;
  line-height: 12.1px;
  opacity: 50%;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.itemvalue{
  font-size: 14px !important;
  font-family: Inter;
  line-height: 14.52px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 0.1em 0 0.8em 0;
}

.item {
  font-family: Inter;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 0 48%; /* Ensures each item takes up 48% of the width for two columns with spacing */
}

.tabButton{
  color: #FFFFFF;
}

.alertsCardBox{
  height: 100%;
}

.alertsCountCircle{
  display: flex;      /* For content alignment */
  justify-content: center;
  align-items: center;
  background-color: #FA5849;
  border-radius: 50%; /* Makes it circular */
  width: 1.5em;       /* Equal width and height */
  height: 1.5em;      /* Equal width and height */
  
}

.triggeredAlertHeader{
  margin-left: 0.5em;
}

.alertCardActions{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
  text-decoration: underline;

}