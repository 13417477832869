
.container {
width: 100%;
// background-color: pink;
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to the next row */
  justify-content: space-between; /* Adds space between the two columns */
//   max-width: 400px; /* Optional: Controls the overall width */
  margin: 0 auto; /* Centers the container horizontally */
}

.item {
  flex: 0 0 48%; /* Ensures each item takes up 48% of the width for two columns with spacing */
  font-size: 2px;
}