.detailInputWrapper {
  background-color: #111326bf;
  padding: 1em 4em;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 100%;

  .inputWrapperMain {
    display: flex;

    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;

    .mfaText {
      font-size: 20px;
    }

    .textFieldWrapper {
      display: flex;
      justify-content: center;
      flex-direction: row;
      gap: 7px;
      width: 69%;

      .textField {
        height: 55px;
        // width: 69%;
        border-radius: 5px;
        border: 2px solid #4d4e5c;
        background-color: #080913;
        padding-right: 5px;
        padding-left: 5px;
      }

      .errorTextField {
        height: 55px;
        // width: 10%;
        border-radius: 5px;
        border: 2px solid #4d4e5c;
        background-color: #080913;
        padding-right: 5px;
        padding-left: 5px;

        input {
          color: red !important;
        }
      }
    }

    .qrCodeImg {
      max-width: 200px;
      width: 100%;
      margin: auto;
    }
  }

  .submitButton {
    display: flex;
    justify-content: center;

    .buttonWrap {
      background-color: #00a3e3;
      width: 69%;
      color: #ffffff;
    }
  }
}

.card-container {
  perspective: 62.5em;
  /* Enables 3D space for the flip */
  width: 45.25em;
}

/* Inner card styles */
.card {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  /* Enable 3D transform */
  transition: transform 1s;
  /* Animation timing */
}

/* Flip the card when the container has "flipped" class */
.card-container.flipped .card {
  transform: rotateY(180deg);
  /* Rotate the card */
}

/* Card faces (front and back) */
.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  /* Hide the back face when flipping */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Back face styles */
.card-back {
  // background: #ffe4e1;
  transform: rotateY(180deg);
  /* Start rotated for flipping effect */
}

.buttonWrap {
  background-color: #00a3e3;
  // width: 69%;
  color: #ffffff;
}

.twoStepVerButton {
  color: white;
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
}

.textMfa {
  text-align: center;
  color: white;
  margin-top: 12px;
}

.errorText {
  color: red;
  font-size: 0.875rem;
  font-weight: 400;
  opacity: 0.9;
  margin-right: auto;
  margin-top: -0.5em;
}

.textUser {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.85;
  margin-bottom: 1em;
}

.resendEmailText {
  color: #00a3e3;
  font-size: 0.875rem;
  font-weight: 400;
  opacity: 0.9;
}
