.webcamModal {
  height: 441px;
  width: 324px;
  border-radius: 8px;
  background: #24263c;
  backdrop-filter: blur(25px);
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 12px;
  border-radius: 8px;
  background: #24263c;
  backdrop-filter: blur(25px);
  font-size: 12px;
  z-index: 999;

  .webCamHeader {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .title {
      font-size: 14px;
      width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .webcamLoadingSpinner {
    height: 306px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .timeSince {
    margin-left: auto;
  }

  .fullWebcamImage {
    height: 300px;
    width: 300px;
  }

  .webcamInfo {
    margin: 10px 0;
    font-size: 12px;
  }

  .webcamThumbnailsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 48px;

    .thumbnailCarousel {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 5px;
      width: calc(100% - 40px); /* Adjust for arrow width + margin */
      max-width: calc(
        5 * 100px + 40px
      ); /* Limit the width to 5 thumbnails + gaps */
    }
  }
}
