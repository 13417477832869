.sideBarMain {
  background-color: #1b1d2d;
  width: 265px;
  border-right: 1px solid #393c56;
  .sideBarSearchMain {
    padding: 24px;
    .sideBarSearchInput {
      border-radius: 4px;
      padding-left: 12px;
      background: #fff;
      .searchInput {
        padding: 12px;
        padding-left: 0;
      }
      .searchInputFocused {
        border-width: 0;
        border-color: transparent;
      }
    }
  }
  .recentWrap {
    padding: 10px 24px;
    color: #fff;
    cursor: pointer;
    &:hover {
      background: #393c56;
    }
    &.activeTab {
      background-color: #24263c;
      border-radius: 4px 4px 0px 0px;
    }
    .recentIcon {
      font-size: 20px;
    }
    .recentHead {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .projectMain {
    color: #fff;
    .projectHeadWrap {
      padding: 10px 24px;
      border-top: 1px solid #393c56;
      border-bottom: 1px solid #393c56;
      .projectHead {
        font-size: 14px;
        font-weight: 700;
      }
    }
    .projectWrap {
      margin: 24px 0;
      .accordionMain {
        background-color: #1b1d2d;
        color: #fff;
        box-shadow: none;
        cursor: pointer;
        &::before {
          background-color: transparent;
        }
        &:hover {
          background: #393c56;
        }
        &.activeTab {
          background-color: #24263c;
          border-radius: 0;
        }
        &.accordionExpanded {
          margin-bottom: 0;
        }
        .accordionWrap {
          padding: 0;
          padding-right: 10px;
          min-height: auto;

          .accordionContent {
            margin: 0;
            justify-content: space-between;
            .projectCard {
              padding: 10px 8px 10px 24px;
              transition: all 0.3s;
              cursor: pointer;
              width: 100%;
              .projectIcon {
                font-size: 20px;
              }
              .projectHead {
                font-size: 14px;
                font-weight: 400;
              }
              .projectEditDelete {
                cursor: pointer;
                font-size: 20px;
              }
            }
          }
        }
        .accordionDetails {
          padding: 0;
          .accordionDetailsWrap {
            .accordionDetails {
              padding: 10px 34px;
              &:hover {
                background: #1b1d2d;
              }
              .detailsIcon {
                font-size: 20px;
              }
              .detailsHead {
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
        .expandMoreIcon {
          cursor: pointer;
          font-size: 20px;
          color: #fff;
        }
      }
    }
    .projectShared {
      padding: 10px 24px;
      transition: all 0.3s;
      cursor: pointer;
      width: 100%;
      border-top: 1px solid #393c56;
      &:hover {
        background: #393c56;
      }
      &.activeTab {
        background-color: #24263c;
        border-radius: 0;
      }
      .projectSharedIcon {
        font-size: 20px;
      }
      .projectSharedHead {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
