body:has(.iconBody) {
  overflow: hidden;
}

.iconBody {
  width: 800px;
  height: 150px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
}

#spinner {
  width: 100%;
  height: 100%;
  animation-name: rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  /* animation-timing-function: cubic-bezier(0,1.19,.99,.9); */
  animation-timing-function: ease-in-out;
  border-radius: 50%;
}

@keyframes rotate {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#earthIcon {
  width: 8.5%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.backdrop {
  width: 0;
  height: 0;
  position: fixed;
  background-color: #fff;
  backdrop-filter: blur(50px);
  opacity: 0.4;
  z-index: 10000;
}
