.sliderMain {
  .sliderRail {
    color: #00a3e3;
  }
  .sliderTrack {
    color: #00a3e3;
  }
  .sliderThumb {
    color: #00a3e3;
    border: 2px solid #ffffff;
  }
  .Tooltip {
    text-transform: lowercase;
  }
}

.tooltipMain {
  background-color: #24263c !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 15px;
  box-shadow: 0px 0px 20px 0px #00000040;
  border-radius: 8px !important;
  margin-top: 8px !important;
  padding: 3px 8px !important;
  .toolTipArrow {
    &:before {
      background-color: #24263c;
    }
  }
}
