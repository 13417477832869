@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

*,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #393c56;
    border-radius: 10px;
  }
}

.layoutWrapper {
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  display: flex;
  height: calc(100vh - 53px);
}

.mainContent {
  width: 100%;
  flex: 1;
  background: #24263c;
  color: #fff;
  position: relative;
}
