.headerWrapper {
  min-height: 50px;
  background-color: #393c56;
  padding: 0 24px;

  .tabIconWrap {
    padding: 0 16px;
    min-height: 48px;
    margin-top: 5px;
    cursor: pointer;
    .tabIcon {
      font-size: 24px;
      fill: #ffffff;
      width: auto;
    }
    &:hover,
    &.activeTab {
      background-color: #24263c;
      border-radius: 4px 4px 0px 0px;
    }
  }
  .headerTabs {
    overflow: auto;
    .tabContainer {
      gap: 8px;
      padding-top: 5px;
      position: relative;

      .headerTab {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: 10px;
        padding: 0;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        padding: 0 16px;
        min-width: auto;
        min-height: 48px;
        overflow: visible;
        &:nth-last-child(2) {
          &::before {
            content: "";
            position: absolute;
            top: -10px;
            right: 0;
            width: 1px;
            background-color: #ffffff4d;
          }
        }
        &.activeTab,
        &:hover {
          background-color: #24263c;
          border-radius: 4px 4px 0px 0px;
          &:nth-last-child(2) {
            &::before {
              content: "";
              position: absolute;
              top: -10px;
              right: 0;
              width: 1px;
              height: 60px;
              background-color: transparent;
            }
          }
        }

        .headerTabIcon {
          margin-bottom: 0;
          font-size: 16px;
        }
      }
    }
    .tabIndicator {
      background-color: transparent;
    }
    :global .MuiTabs-scrollButtons.Mui-disabled {
      opacity: 0.3;
    }
  }
  .notificationIcon {
    position: relative;
    // cursor: pointer;
    pointer-events: none;
    opacity: 0.5;
  }
  .icon {
    font-size: 20px;
    fill: #ffffff;
  }
}

.logoutButtonWrap {
  cursor: pointer;
}
