.tooltipMain {
  padding: 12px !important;
  background-color: #24263c !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17px;
  box-shadow: 0px 0px 20px 0px #00000040;
  border-radius: 8px !important;
  .toolTipArrow {
    &:before {
      background-color: #24263c;
    }
  }
}
