.notificationMain {
  position: absolute;
  top: 55px;
  right: -40px;
  background: #393c56;
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px #00000017;
  transition: all 5s ease-in-out;
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 40px;
    border-bottom: 10px solid #393c56;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
  .notificationHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #24263c;
    padding-bottom: 5px;
    margin-bottom: 15px;
    .notificationHeadIcon {
      cursor: pointer;
      font-size: 20px;
    }
  }
  .notificationWrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .readAllWrapper {
      display: flex;
      justify-content: flex-end;
      .readAllButton {
        color: #fff;
        background: transparent;
        padding: 5px 15px;
        font-size: 13px;
        font-weight: 500;
        text-transform: capitalize;
        margin-right: 15px;
        svg {
          font-size: 19px;
        }
      }
    }
    .notificationCard {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cardHead {
        font-size: 16px;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
    .CardAddText {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .notificationButtonWrap {
    display: flex;
    align-items: center;
    gap: 15px;
    .acceptButton {
      border: 1px solid #fff;
      color: #fff;
      background: transparent;
      padding: 5px 15px;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      transition: all 0.5s;
      &:hover {
        background: #fff;
        color: #24263c;
      }
    }
    .rejectButton {
      border: 1px solid #e14949;
      color: #e14949;
      background: transparent;
      padding: 5px 15px;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      &:hover {
        background: #e14949;
        color: #fff;
      }
    }
  }
}
