.tableWrap {
  border: none !important;

  .tableHeader {
    background-color: #24263c !important;
    text-transform: capitalize !important;

    .tableHeaderRow {
      border: none !important;

      .tableHeaderCell {
        background-color: #24263c !important;
        color: white !important;
        border: none !important;
        font-size: 12px !important;
        padding: 0 5px !important;
      }
    }
  }

  .tableRow {
    width: 50px;
    cursor: pointer;
    .tableCell {
      color: white !important;
      border: none !important;
      font-size: 12px !important;
      padding: 8px 5px !important;
    }
  }
}
