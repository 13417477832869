.dialogTitle {
  color: "#ffffff";
}

.allResultsRowName {
  margin-left: 8px;
  max-width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.allResultsDialog {
  z-index: 20002;

  .dialogTitle {
    color: #ffffff;

    .dialogTitleHeader {
      display: flex;
      align-items: center;

      .dialogTitleText {
        font-size: 16px;
      }
    }
  }

  .dialogContent {
    height: 90vh;
    color: #ffffff;
  }

  .dialogContentButtons {
    margin-bottom: 14px;
    display: flex;
    align-items: center;

    .dialogContentDivider {
      width: 100%;
      border-bottom: 1px solid gray;
      margin-top: auto;
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  width: 800px;
}

.paginationNavigationOption {
  color: #393c56;

  li {
    :global .MuiPaginationItem-root {
      color: #ffffff;
    }
    button {
      background-color: #393c56;
      &:hover {
        background-color: #00a3e3;
      }
    }
    :global .Mui-selected {
      background-color: #00a3e3;
      &:hover {
        background-color: #00a3e3;
      }
    }
  }
}

.paginationNavigationItem {
  color: #ffffff;
  background-color: #393c56;
}

.allResultsPagination {
  align-self: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 100%;
}

.pageLabel {
  margin-right: 12px;
}

.goToPageButton {
  height: 35px;
  color: #00a3e3;
  background-color: #00a3e3;
  &:hover {
    background-color: #393c56;
  }
}
