.loginMain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../public/images/map-background.png") no-repeat center;
  background-size: cover;
  .loginOverlay {
    width: 100%;
    height: 100%;
    background-color: #24263c;
    mix-blend-mode: color;
  }
  .loginWrap {
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    @media screen and (max-width: 991px) {
      width: 90%;
    }
  }
}
.loginDetail {
  display: flex;
  flex-direction: column;
  gap: 26px;
  background: #111326;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 36px 72px;
  width: 100%;
  height: calc(100vh - 130px);
  max-height: fit-content;
  overflow-y: auto;
  @media screen and (max-width: 767px) {
    padding: 20px 30px;
  }
  .loginDetailHead {
    font-family: "Inter";
    font-size: 22px;
    font-weight: 400;
    line-height: 29px;
    color: #ffffff;
    text-align: center;
  }
  .loginDetailDescription {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: #fff;
    text-align: center;
  }

  .detailInputWrapper {
    display: flex;
    flex-direction: column;
    gap: 18px;
    .inputWrapperMain {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      .inputPasswordInfo {
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        color: #ffffff;
      }
    }
    .inputLinkWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
      }
      .inputLabel {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #ffffff;
        .inputCheckbox {
          color: #fff;
        }
      }
    }
    .buttonWrap {
      background-color: #00a3e3;
      width: 222px;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      padding: 8px 10px;
      text-transform: capitalize;
      margin: 20px auto 0px auto;
      color: #fff;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .accountWrapper {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
    .inputLink {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      color: #fff;
      cursor: pointer;
    }
    .errorMessage {
      display: flex;
      color: #fff;
      text-align: center;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }
}

.backButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
