.sanctionedMain {
  gap: 16px;
  .detailWrap {
    gap: 2px;
    .detailHead {
      font-family: "Inter";
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      color: #7d7d84;
    }
    .detailValue {
      font-family: "Inter";
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
    }
  }
}
