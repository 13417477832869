.mainSearchBar {
  display: flex;
  align-items: center;

  .searchAutoComplete {
    width: 700px;
    background-color: #000000;

    .searchTextField {
      .searchIcon {
        margin-left: 12px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}

.noResultsFound {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 55px;
  color: gray;
}

.searchHeader {
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;

  .searchFilterButton {
    display: flex;
    height: 10px;
    text-transform: none;
    padding-bottom: 18px;
    min-width: max-content;
    white-space: nowrap;
    border-radius: 0;
  }

  .headerDivider {
    width: 100%;
    border-bottom: 1px solid gray;
    margin-top: auto;
  }
}

.searchItemName {
  font-size: 16px;
  margin-right: 8px;
  margin-left: 8px;
}

.showAllResults {
  margin-left: 12px;
  padding-bottom: 12px;
  text-decoration: none;

  .showResultsLink {
    display: flex;
    align-items: center;
    color: #0094ff;
  }

  .showAllResultsText {
    margin-right: 6px;
  }
}

.searchClearButton {
  margin-left: 50px;
  height: 14px;

  .searchClearIcon {
    color: #ffffff;
  }
}
