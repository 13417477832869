.searchListItem {
  background-color: #000000;
  color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 163, 227, 0.5);
  }

  .searchItemDivider {
    background-color: #393c56;
  }

  .searchItemRow {
    margin-top: 0px;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;

    .searchItemTitle {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
    }

    .searchItemRowData {
      display: flex;
      align-items: center;

      .searchColumn {
        flex: 1 1 auto;
      }

      .searchRowTitle {
        font-size: 10px;
        color: gray;
      }

      .searchRowData {
        width: 120px;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
