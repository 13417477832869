.singleFilterbox {
  margin-top: 1rem;
}
.shipFiltersMain {
  .shipFiltersHead {
    background: #24263c;
    padding: 15px;
    color: #fff;
    span {
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .icon {
      cursor: pointer;
    }
  }
  .shipFiltersWrapper {
    background-color: #393c56;
    padding: 24px 15px;
    overflow-y: scroll;
    height: 60vh;
    .clearInputIcon {
      margin-left: auto;
      cursor: pointer;
    }
    .shipFilterDisable {
      opacity: 0.4;
      cursor: not-allowed;
    }
    .shipFiltersDetail {
      .shipFiltersLabel {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #ffffff;
      }
      .editMainSelect {
        color: #fff;
      }
      .countrySelectMain {
        .countrySelect {
          border: 1px solid #ffffff40;
          background: #00000080;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: #ffffff;
          padding: 8px 12px;
          border-radius: 4px;
          &:has(.countryInput:focus) {
            border-color: #00a3e3;
          }
          .countrySelectImage {
            height: 16px;
            margin-right: 6px;
          }
          .countryInput {
            min-height: 20px;
            border: 0;
            background: transparent;
            padding: 0;
            outline: none;
            color: #fff;
          }
        }
      }
      .shipFiltersListWrap {
        border: 1px solid #ffffff40;
        max-height: 160px;
        overflow: hidden;
        border-radius: 4px;
        margin-bottom: 5px !important;

        ::-webkit-scrollbar {
          display: none;
        }
        .shipFiltersListHead {
          background: #26262c;
          .shipFiltersListBox {
            padding: 0;
            .shipFiltersList {
              padding: 8px 12px;
              .listImage {
                height: 16px;
              }
              .listLabel {
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                color: #fff;
              }
              &:hover,
              &.active {
                background-color: #00a3e34d;
              }
            }
          }
        }
      }
      .colorPickerMain {
        .colorPicker {
          width: 100%;
        }
      }
      .sliderWrapper {
        .slider {
          width: 90%;
          margin: 0 auto;
          .sliderRail {
            color: #d9d9d9;
          }
          .sliderTrack {
            color: #ffffff;
          }
          .sliderThumb {
            color: #0094ff;
            border: 2px solid #fff;
          }
        }
      }
      .checkboxMain {
        color: #fff;
        padding: 0;
        &.checkboxChecked {
          color: #0094ff;
        }
      }
    }
  }
  .filterButton {
    padding: 0 15px 24px;
    .shipFiltersButton {
      border: 1px solid #ffffff;
      padding: 8px;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: #ffffff;
      text-transform: capitalize;
    }
  }
}
