.ShipDetailsMain {
  background-color: #111326;
  // border: 1px solid #393c56;

  .ShipDetailsHead {
    padding: 16px;
    .headWrap {
      .shipSpoofingImage {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
      .headDetailWrap {
        width: 100%;
        gap: 10px;
        .countryWrap {
          .countryName {
            display: flex;
            justify-content: center;
            align-items: center;
            .countryNameHead {
              font-family: "Inter";
              font-size: 14px;
              font-weight: 400;
              line-height: 16px;
              color: #ffffff;
            }
            .countryFlag {
              display: flex;
              align-items: center;
              height: 13.5px;
              width: 18px;
              line-height: 16px;
            }
          }
          .notifications {
            .notificationsIcon {
              cursor: pointer;
              &.active {
                background-color: #1b1d2d;
                border-radius: 4px;
              }
            }
          }
        }
        .shipDetailWrap {
          width: 100%;
        }
        .shipDetail {
          gap: 2px;
          .detailLabel {
            font-family: "Inter";
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            color: #7d7d84;
          }
          .detailValue {
            display: flex;
            align-items: center;
            gap: 12px;
            font-family: "Inter";
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            color: #ffffff;
            svg {
              width: 20px;
              height: 20px;
            }
          }
          &:first-child {
            .detailValue {
              color: #0094ff;
            }
          }
        }
      }
    }
    .shipToolWrap {
      gap: 10px;
      .shipTool {
        gap: 10px;
      }
    }
  }

  .shipDetailsTab {
    border-bottom: 1px solid #393c56;
    .shipTab {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #7d7d84;
      padding: 13px 16px;
      text-transform: capitalize;
      &.shipTabSelected {
        color: #fff;
      }
      &.shipTabDisabled {
        pointer-events: all !important;
        cursor: not-allowed !important;
      }
    }
    .shipTabIndicator {
      background-color: #fff;
    }
    .shipTabContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .shipDetailsTabsWrap {
    background-color: #1b1d2d;
    &:global {
      &.MuiTabs-root {
        .MuiButtonBase-root {
          &.Mui-disabled {
            opacity: 0.5;
            .MuiSvgIcon-root {
              opacity: 0.5;
            }
          }
        }
      }
    }
    .scrollBtn {
      border-left: 1px solid #393c56;
      border-bottom: 1px solid #393c56;
      border-right: 1px solid #393c56;
    }
    .shipDetailsTabsFlexContainer {
      .shipDetailsTab {
        width: fit-content;
        white-space: pre;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #fff;
        padding: 5px;
        text-transform: capitalize;
        border-left: 1px solid #393c56;
        border-bottom: 1px solid #393c56;
        &:first-child {
          border-left: none;
        }
        &.tabSelected {
          border-bottom: 0;
          background-color: #111326;
          .shipDetailsTabItem {
            .tabCloseBtn {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        .shipDetailsTabItem {
          svg {
            width: 16px;
            height: 16px;
          }
          .tabCloseBtn {
            width: 16px;
            height: 16px;
            color: #fff;
            opacity: 0;
            visibility: hidden;
          }
          .tabName {
            font-size: 13px;
            white-space: nowrap;
            max-width: 270px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .shipTabIndicator {
      background-color: transparent;
    }
  }
  .shipTabMain {
    width: 100%;
    .tabPanel {
      height: calc(100vh - 500px);
      overflow-y: auto;
    }
    .shipDetailsTab {
      border-bottom: 1px solid #393c56;
      .shipTab {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #7d7d84;
        padding: 13px 16px;
        text-transform: capitalize;
        &.shipTabSelected {
          color: #fff;
        }
        &.shipTabDisabled {
          pointer-events: all !important;
          cursor: not-allowed !important;
        }
      }
      .shipTabIndicator {
        background-color: #fff;
      }
    }
  }
  .satelliteImage {
    border-radius: 4px;
    cursor: pointer;
  }
  .shipDetail {
    gap: 2px;
    .detailLabel {
      font-family: "Inter";
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      color: #7d7d84;
    }
    .detailValue {
      display: flex;
      align-items: center;
      gap: 12px;
      font-family: "Inter";
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #ffffff;
    }
  }
  .satelliteImageInfo {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .satelliteSortBy {
    color: white;
  }
  .imageContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-height: calc(100vh - 242px);
    overflow-y: auto;
  }
  .loader {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
