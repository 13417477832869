.estimatedLocations {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #393c56;
  .estimatedLocationsHead {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 12px;
  }
  .sliderMain {
    .sliderRail {
      color: #00a3e3;
    }
    .sliderTrack {
      color: #00a3e3;
    }
    .sliderThumb {
      color: #00a3e3;
      border: 2px solid #ffffff;
    }
  }
  .estimatedDate {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
  .estimatedIcon {
    cursor: pointer;
  }
}
